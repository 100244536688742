// TO DO: Make the arrow inside it's own div like 20px on the right side of the button then just make them columns

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomLink from "../Link";
import styles from "../../pages/styles/Projects.module.css";
// import gsap from "gsap";

const ProjectOutboundButton = ({ url, text, buttonType }) => {
  const [opacity, setOpacity] = useState(0);
  const [isFullyIlluminated, setIsFullyIlluminated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = window.innerHeight;
      const triggerHeight = 0.75 * viewportHeight;

      if (scrollPosition <= triggerHeight && !isFullyIlluminated) {
        setOpacity(scrollPosition / triggerHeight);
      } else if (scrollPosition > triggerHeight && !isFullyIlluminated) {
        setOpacity(1);
        setIsFullyIlluminated(true);
      }
    };

    if (window.innerWidth <= 768) {
      window.addEventListener("scroll", handleScroll);
    } else {
      setOpacity(1);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFullyIlluminated]);

  if (buttonType === "source") {
    return (
      <Link
        className={`${styles.projectCTAButton} ${styles.projectCTAButtonSource}`}
        to={url}
      >
        <div id="text">{text}</div>
        <div className={styles.projectCTAButtonArrow}>{"→"}</div>
      </Link>
    );
  } else {
    return (
      <CustomLink
        className={`${styles.projectCTAButton} ${styles.projectCTAButtonNextProject}`}
        to={url}
        style={{ opacity: opacity }}
      >
        <div id="text"> {text}</div>
        <div className={`${styles.projectCTAButtonArrow}`}> {"→"}</div>
      </CustomLink>
    );
  }
};

export default ProjectOutboundButton;
