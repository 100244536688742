import React, { useRef, useEffect } from "react";
import styles from "../styles/Header.module.css";
import memojiPhotoo from "../../assets/memoji.png";
import { gsap } from "gsap";

export default function Memoji() {
  const memojiRef = useRef(null);

  useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: memojiRef.current,
        start: "center top", // Start the animation when the top of the trigger is at 75% of the viewport height
        end: "center top", // End the animation when the top of the trigger is at 25% of the viewport height
        toggleActions: "play none none reverse", // Play the animation when entering, reverse it when leaving
      },
    });

    timeline.to(memojiRef.current, {
      y: "-100px",
      duration: 0.5,
      ease: "power3.inOut",
    });

    return () => {
      if (timeline.scrollTrigger) timeline.scrollTrigger.kill();
    };
  }, []);

  return (
    <div className={styles.memoji} ref={memojiRef}>
      <img src={memojiPhotoo} alt="memoji" />
    </div>
  );
}
