import React, { useEffect } from "react";
import styles from "./styles/About.module.css";
import "../globals.css";
import { Helmet } from "react-helmet";
import Hero from "../components/Hero";
import AboutResumeBento from "../components/about/AboutResumeBento";
import AboutButtons from "../components/about/AboutButtons";

const About = () => {
  const cta = "#d1d365";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta); // Set the color of the CTA button
  }, []);
  return (
    <>
      <Helmet>
        <title>Maguire McMahon · About</title>
        <meta name="theme-color" content={cta}></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content={cta}></meta>
        <meta
          name="description"
          content="Maguire McMahon is a full-stack developer with a passion for creating unique, engaging web experiences."
        />
      </Helmet>
      <div className="page-layout">
        <Hero
          h1={"the path less traveled"}
          h2={"the traditional path vs."}
          cta={cta}
        ></Hero>
        <div className={styles.bentoContainer}>
          <AboutButtons />
          <AboutResumeBento />
        </div>
      </div>
    </>
  );
};

export default About;
