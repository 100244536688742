import React from "react";
import styles from "../styles/About.module.css";

const BentoResume = () => {
  return (
    <div className={styles.bentoResume}>
      <h2>Education</h2>
      <h3>Virginia Tech</h3>
      <ul>
        <li>Bachelor of Science in Industrial & Systems Engineering</li>
        <li>GPA: 3.2 | Major GPA: 3.8</li>
        <li>Dean's List: Spring & Fall '23, Spring '24</li>
        <li>Minors in Computer Science & Human-Computer Interaction</li>
      </ul>
      <h3>Codecademy</h3>
      <ul>
        <li>Front-End Engineering Career Course & Certification</li>
        <li>
          Completed a 350-hour course covering HTML, CSS, Javascript, React, and
          Git
        </li>
      </ul>

      <h2>Experience</h2>
      <div className={styles.experienceItem}>
        <p className={styles.jobTitle}>
          <strong>
            Product Management Intern
            <span>
              @ <span className={styles.walmart}>Walmart</span>
            </span>
          </strong>

          <span>Summer '24</span>
        </p>
        <ul>
          <li>
            Developed five products, created PRDs, JIRA Epics, KPIs,
            requirements, user stories, and roadmaps, reducing costs by over
            $400,000 within the realty supply chain network.
          </li>
        </ul>
      </div>
      <div className={styles.experienceItem}>
        <p className={styles.jobTitle}>
          <strong>
            Undergraduate Researcher
            <span>
              @ <span className={styles.virginiaTech}>Virginia Tech</span>
            </span>
          </strong>

          <span>Spring '24</span>
        </p>
        <ul>
          <li>
            Created four unique optimization models and visualizations using
            Python and Gurobi to reduce the spread of invasive species across
            lakes in Minnesota, considering cooperative counties using
            game-theory fundamentals.
          </li>
        </ul>
      </div>
      <div className={styles.experienceItem}>
        <p className={styles.jobTitle}>
          <strong>
            Assistant Book Author & Editor
            <span>
              @ <span className={styles.virginiaTech}>Virginia Tech</span>
            </span>
          </strong>

          <span>Spring '24</span>
        </p>
        <ul>
          <li>
            Contributed to a book on Optimization, specifically integer and
            linear programming.
          </li>
          <li>
            Crafted over ten example problems in LaTeX and supporting code in
            Python.
          </li>
        </ul>
      </div>
      <div className={styles.experienceItem}>
        <p className={styles.jobTitle}>
          <strong>
            Code Categorizer & Evaluator
            <span>
              @<span className={styles.scaleAI}>Scale A.I.</span>
            </span>
          </strong>

          <span>Summer '23</span>
        </p>
        <ul>
          <li>
            Contributed to the training of LLMs by evaluating and categorizing
            over 1000 prompts and answers.
          </li>
        </ul>
      </div>
      <div className={styles.experienceItem}>
        <p className={styles.jobTitle}>
          <strong>
            Website Development Team Lead
            <span>
              @ <span className={styles.ruggedOrganics}>Rugged Organics</span>
            </span>
          </strong>
          <span>Fall '22-'23</span>
        </p>
        <ul>
          <li>
            Managed front & back end of the website and a team of seven interns.
          </li>
          <li>
            Improved site performance, reducing load times from 5.5s to 1.5s.
          </li>
        </ul>
      </div>

      <h2>Extracurriculars</h2>
      <div className={styles.experienceItem}>
        <p className={styles.jobTitle}>
          <strong>
            Professional Development Board Member
            <span>
              @ <span className={styles.companyName}>Tau Kappa Epsilon</span>
            </span>
          </strong>

          <span>Spring '20-Present</span>
        </p>
        <ul>
          <li>
            Raised over $1,000,000 for St. Jude Children's Research Hospital
            across 4 years.
          </li>
          <li>
            Coached organizations to raise $30,000 in three years, with over
            $10,000 on my personal page.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BentoResume;
