import React from "react";
import styles from "../../pages/styles/Projects.module.css";
import { motion, AnimatePresence } from "framer-motion";

const ProjectContent = ({ children }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.projectContent}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default ProjectContent;
