import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Header from "../components/header/Header";
import Footer from "../components/Footer";
import LoadingScreen from "../components/LoadScreen";
import { useLoading } from "../context/LoadingContext";
import { motion, AnimatePresence } from "framer-motion";

const Layout = () => {
  const location = useLocation();
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);

      // Simulate a loading delay
      setTimeout(() => {
        setLoading(false);
      }, 660); // Adjust the delay as needed
    };

    handleRouteChange();
  }, [location, setLoading]);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.set({ page: currentPath });

    // Extract the title of the page
    const title = document.title;
    ReactGA.send({ hitType: "pageview", page: currentPath, title: title });
  }, [location]);

  return (
    <>
      <LoadingScreen loading={loading} />
      <Header />
      <AnimatePresence>
        <motion.div
          className="page-layout"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Outlet />
        </motion.div>
      </AnimatePresence>
      <Footer />
    </>
  );
};

export default Layout;
