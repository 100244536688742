import React from "react";
import styles from "../styles/WhoopModal.module.css";
import { formatTo12Hour, subtractHours } from "../../utils/timeConversion";
import WhoopModalCircle from "./WhoopModalCircle";
import WhoopWorkout from "./WhoopWorkout";
import WhoopSleep from "./WhoopSleep";
import LoadingSpinner from "../LoadingSpinner";
import whoopLogoLarge from "../../assets/whoopLogoLarge.png";

export default function WhoopModal({
  isModalOpen,
  toggleModal,
  sleepInfo,
  cycleInfo,
  workoutInfo,
  recoveryInfo,
}) {
  // Handles not displaying the modal if it's not open
  if (!isModalOpen) return null;

  // Handles displaying a loading spinner and not trying to process the data if the scores are not available/loaded
  if (
    recoveryInfo.score === 0 ||
    sleepInfo.score === undefined ||
    cycleInfo.score === undefined
  )
    return (
      <div className={`${styles.whoopModal}`}>
        <div className={styles.whoopModalContent}>
          <button className={styles.exitButton} onClick={toggleModal}>
            X
          </button>
          <h2>Whoop Statistics - </h2>
          <LoadingSpinner />
        </div>
      </div>
    );

  let fillColor = "#FF0026";
  if (recoveryInfo.score >= 66) {
    fillColor = "#16ec06";
  } else if (recoveryInfo.score >= 34) {
    fillColor = "#FFDE00";
  }

  // Format today's date
  const todaysDate = new Date();
  const date = todaysDate.getDate();
  const month = todaysDate.getMonth() + 1;
  const year = todaysDate.getFullYear();
  const dateFormatted = `${month}/${date}/${year}`;

  // Round the cycle score to two decimal places
  cycleInfo.score = Math.round(cycleInfo.score * 10) / 10;

  // ROund the HRV
  recoveryInfo.hrv = Math.round(recoveryInfo.hrv);

  // Convert the start and end times to 12-hour format, with AM/PM, and remove the seconds
  // Make sure it's converted to central time zone (UTC-5) i.e. subtract 5 hours
  let sleepStartTime = sleepInfo.start.slice(11, 16);
  let sleepEndTime = sleepInfo.end.slice(11, 16);
  let totalTimeAsleep = new Date(sleepInfo.end) - new Date(sleepInfo.start);
  const totalTimeAsleepHours = Math.floor(totalTimeAsleep / 1000 / 60 / 60);
  const totalTimeAsleepMinutes = Math.floor(totalTimeAsleep / 1000 / 60) % 60;
  const totalTimeAsleepMinutesFormatted =
    totalTimeAsleepMinutes < 10
      ? "0" + totalTimeAsleepMinutes
      : totalTimeAsleepMinutes;
  totalTimeAsleep =
    totalTimeAsleepHours + ":" + totalTimeAsleepMinutesFormatted;
  sleepStartTime = formatTo12Hour(subtractHours(sleepStartTime, 5));
  sleepEndTime = formatTo12Hour(subtractHours(sleepEndTime, 5));

  // Handling opening and closing the modal
  const handleClickOutside = (event) => {
    if (event.target.closest(`.${styles.whoopModalContent}`) === null) {
      toggleModal();
    }
  };

  // Rendering the workouts
  const whoopWorkouts = workoutInfo.map((workout, index) => {
    return (
      <WhoopWorkout
        key={index}
        score={workout.score}
        start={workout.start}
        end={workout.end}
      />
    );
  });

  return (
    <div className={`${styles.whoopModal}`} onClick={handleClickOutside}>
      <div className={styles.whoopModalContent}>
        <button className={styles.exitButton} onClick={toggleModal}>
          X
        </button>
        <h2>Whoop Statistics - {dateFormatted}</h2>

        <WhoopModalCircle
          recoveryScore={recoveryInfo.score}
          strainScore={cycleInfo.score}
        >
          <div className={styles.circleInfoColOne}>
            <div className={styles.circleInfoContainer}>
              <p
                className={`${styles.recovery} ${styles.number}`}
                style={{ color: fillColor }}
              >
                {recoveryInfo.score}%{" "}
              </p>
              <p className={styles.recovery} style={{ color: fillColor }}>
                Recovery
              </p>
            </div>
            <div className={styles.circleInfoContainer}>
              <p className={styles.circleInfoHeader}>HR</p>
              <p className={`${styles.circleInfoText} ${styles.number}`}>
                {recoveryInfo.hr}
              </p>
            </div>
          </div>
          <div className={styles.circleInfoColTwo}>
            <div className={styles.circleInfoContainer}>
              <p className={`${styles.strain} ${styles.number}`}>
                {cycleInfo.score}
              </p>
              <p className={styles.strain}>Strain</p>
            </div>
            <div className={styles.circleInfoContainer}>
              <p className={styles.circleInfoHeader}>HRV</p>
              <p className={`${styles.circleInfoText} ${styles.number}`}>
                {recoveryInfo.hrv}
              </p>
            </div>
          </div>
        </WhoopModalCircle>

        {whoopWorkouts}
        <WhoopSleep
          score={totalTimeAsleep}
          start={sleepStartTime}
          end={sleepEndTime}
        />
        <a
          className={styles.poweredByWhoop}
          href="https://www.whoop.com/"
          target="_blank"
          rel="noreferrer"
        >
          <p>Powered by</p>
          <img src={whoopLogoLarge} alt="Whoop"></img>
        </a>
      </div>
    </div>
  );
}
