import React from "react";
import CustomLink from "../Link";
import BentoBox from "../BentoBox";
import styles from "../styles/Bento.module.css";
import bentoAsset from "../../assets/BentoJarvis.webp";

const BentoJarvis = () => {
  return (
    <CustomLink to="/jarvis" className={styles.bentoJarvis}>
      <BentoBox>
        <h3>Voice Activated Assistant</h3>
        <h2>Jarvis</h2>
        <img src={bentoAsset} alt="BentoJarvis" className={styles.bentoJarvisImg} />
      </BentoBox>
    </CustomLink>
  );
};

export default BentoJarvis;
