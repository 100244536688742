import React from "react";
import styles from "../styles/About.module.css";
import githubLogo from "../../assets/githubLogo.png";
import linkedinLogo from "../../assets/BentoLinkedIn.svg";
import horizon from "../../assets/AboutHorizon.JPG";

const AboutButtons = () => {
  return (
    <div className={styles.bentoButtons}>
      <a
        href="https://github.com/mimcmahon20"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.githubLogo}
      >
        <img src={githubLogo} alt="Github Logo" className={styles.githubLogo} />
      </a>
      <div className={styles.photo}>
        <img src={horizon} alt="Bento Resume" className={styles.photo} />
      </div>
      <a
        href="https://linkedin.com/in/maguire-mcmahon/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.linkedinLogo}
      >
        <img
          src={linkedinLogo}
          alt="LinkedIn Logo"
          className={styles.linkedinLogo}
        />
      </a>
    </div>
  );
};

export default AboutButtons;
