import React from "react";
import CustomLink from "../Link";
import BentoBox from "../BentoBox";
import styles from "../styles/Bento.module.css";
import bentoAsset from "../../assets/BentoWeightClub.webp";

const BentoWeightClub = () => {
  return (
    <CustomLink to="/weightclub" className={styles.bentoWeightClub}>
      <BentoBox>
        <h3>Website Design & Development</h3>
        <h2>The Weight Club</h2>
        <img src={bentoAsset} alt="Bento Weight Club" className={styles.bentoWeightClubImg} />
      </BentoBox>
    </CustomLink>
  );
};

export default BentoWeightClub;
