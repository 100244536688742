import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';

const CustomLink = ({ to, ...props }) => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      navigate(to);
    }, 500)
  };

  return <Link to={to} {...props} onClick={handleClick} />;
};

export default CustomLink;