import React from "react";
import { formatTo12Hour, subtractHours } from "../../utils/timeConversion";
import styles from "../styles/WhoopModal.module.css";
import whoopWeightLift from "../../assets/whoopWeightLift.png";

export default function WhoopWorkout({ score, start, end }) {
  // Convert the start and end times to 24-hour format, with AM/PM, and remove the seconds
  // Make sure it's converted to central time zone (UTC-5) i.e. subtract 5 hours
  let workoutStartTime = start.slice(11, 16);
  let workoutEndTime = end.slice(11, 16);
  workoutStartTime = formatTo12Hour(subtractHours(workoutStartTime, 5));
  workoutEndTime = formatTo12Hour(subtractHours(workoutEndTime, 5));

  score = Math.round(score * 10) / 10;

  return (
    <div className={`${styles.container}`}>
      <div className={styles.initialContainer}>
        <p className={`${styles.text} ${styles.strainBg}`}>
          <img src={whoopWeightLift} alt="weight lifting" />
          {score}
        </p>
        <p className={styles.header}>Workout</p>
      </div>
      <div className={styles.time}>
        <p>{workoutEndTime}</p>
        <p>{workoutStartTime}</p>
        <div className={`${styles.timeLine} ${styles.strainBg}`}></div>
      </div>
    </div>
  );
}
