// LoadingScreen.js
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styles from "./styles/LoadingScreen.module.css";

const LoadingScreen = ({ loading }) => {
  const isMounted = useRef(false);

  useEffect(() => {
    const timeline = gsap.timeline();

    // On subsequent loads, animate columns normally
    if (!isMounted.current) {
        isMounted.current = true;
        return;
    }
    if (loading) {
      timeline
        .set(".loading-column", { opacity: 1 })
        .to(".loading-column", {
          y: "0%",
          stagger: 0.05,
          duration: 0.3,
          ease: "power3.out",
        });
    } else {
      timeline
        .to(".loading-column", {
          y: "-100%",
          stagger: 0.05,
          duration: 0.3,
          ease: "power3.out",
        })
        .set(".loading-column", { opacity: 0, y: "100%" }); // Reset columns to below the screen
    }

    return () => {
      timeline.kill();
    };
  }, [loading]);

  useEffect(() => {
    // After initial animation, add transition to background-color
    if (isMounted.current) {
      const columns = document.querySelectorAll(".loading-column");
      columns.forEach((column) => {
        column.style.transition = "background-color 0.6s ease-out";
      });
    }
  }, []);

  return (
    <div id="loading-screen" className={styles.loadingScreen}>
      {[...Array(6)].map((_, index) => (
        <div
          key={index}
          className={`loading-column ${styles.loadingColumn}`}
        ></div>
      ))}
    </div>
  );
};

export default LoadingScreen;
