import React from "react";
import styles from "./styles/Footer.module.css";
import CustomLink from "./Link";
import HoverCard from "./HoverCard";

const Footer = () => {
  return (
    <footer>
      <div className={styles.footerBackground}></div>
      <div className={styles.horizontalFlex}>
        <div className={styles.footerContainerLeft}>
          <div className={styles.footerAnchorContainer}>
            <CustomLink to="/">Home</CustomLink>
          </div>
          <div className={styles.footerAnchorContainer}>
            <CustomLink to="/about">About</CustomLink>
          </div>
          <div className={styles.footerAnchorContainer}>
            <CustomLink to="/#projects">All Projects</CustomLink>
          </div>
        </div>
        <div className={styles.footerContainerRight}>
          <div className={styles.footerAnchorContainer}>
            <a
              href="https://github.com/mimcmahon20"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
          </div>
          <div className={styles.footerAnchorContainer}>
            <a href="/resume.pdf" target="_blank">
              Resume
            </a>
          </div>
          <div className={styles.footerAnchorContainer}>
            <a
              href="https://www.linkedin.com/in/maguire-mcmahon/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footerContainerCenter}>
        <HoverCard trigger={<p>© 2024 Maguire McMahon</p>}>
          <div>
            <h4>Thanks for exploring {":)"}</h4>
            <p>React, CSS Modules, and GSAP for animations.</p>
            <p>Updated: 07/02/24</p>
          </div>
        </HoverCard>
      </div>
    </footer>
  );
};

export default Footer;
