import React, { useEffect, useRef } from "react";
import styles from "./styles/Hero.module.css";
import gsap from "gsap";
import ThreeDScene from "./ThreeDScene";

import { motion, AnimatePresence } from "framer-motion";

const Hero = ({ h1, h2, cta, children }) => {
  const mainTextRef = useRef(null);
  const secondaryTextRef = useRef(null);
  const childrenRef = useRef(null);

  //useEffect to animate text
  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: "expo.out" } });
    // tl.fromTo(mainTextRef.current, {opacity: 0, y: '100%'}, {opacity: 1, y: '0%', duration: 1.5});
    // tl.fromTo(secondaryTextRef.current, {opacity: 0, y: '100%'}, {opacity: 1, y: '0%', duration: 1.5, delay: .4}, '-=1.5');
    tl.fromTo(
      mainTextRef.current,
      { opacity: 0, rotateX: "100%" },
      { opacity: 1, rotateX: "0%", duration: 1.5, delay: 0.8 }
    );
    tl.fromTo(
      secondaryTextRef.current,
      { opacity: 0, rotateX: "100%" },
      { opacity: 1, rotateX: "0%", duration: 1.5, delay: 0.4 },
      "-=1.5"
    );
    tl.fromTo(
      childrenRef.current,
      { opacity: 0, rotateX: "-100%" },
      { opacity: 1, rotateX: "0%", duration: 1.5, delay: 0.4 },
      "-=1.5"
    );
  }, []);
  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit = {{ opacity: 0 }}
        >
          <ThreeDScene cta={cta} />
        </motion.div>
      </AnimatePresence>
      <div className={styles.hero}>
        <h2 className={styles.headerSecondary} ref={secondaryTextRef}>
          {h2}
        </h2>
        <h1 className={styles.headerMain} ref={mainTextRef}>
          {h1}
        </h1>
        <div className={styles.childrenContainer} ref={childrenRef}>{children}</div>
      </div>
    </>
  );
};

export default Hero;
