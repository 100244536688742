// This component will act as the Recovery Score display, initially fetching the score from the backend and passing it as a prop to the Recovery component. It will also handle opening and closing the modal when the user clicks on the recovery component that will display more information.

import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  getRecoveryScore,
  getSleepScore,
  getCycleScore,
  getWorkoutInformation,
} from "../../utils/whoopAPI";
import styles from "../styles/Header.module.css";
import Recovery from "./Recovery";
import WhoopModal from "./WhoopModal";

gsap.registerPlugin(ScrollTrigger);

const Whoop = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recoveryInfo, setRecoveryInfo] = useState({ score: 0, hr: 0, hrv: 0 });
  const [sleepInfo, setSleepInfo] = useState({});
  const [cycleInfo, setCycleInfo] = useState({});
  const [workoutInfo, setWorkoutInfo] = useState([]);
  const whoopRef = useRef(null);

  useEffect(() => {
    const hitDatabase = async () => {
      const recoveryScoreFromDB = await getRecoveryScore();
      setRecoveryInfo(recoveryScoreFromDB);
      const sleepInfoFromDB = await getSleepScore();
      setSleepInfo(sleepInfoFromDB);
      const cycleInfoFromDB = await getCycleScore();
      setCycleInfo(cycleInfoFromDB);
      const workoutInfoFromDB = await getWorkoutInformation();
      setWorkoutInfo(workoutInfoFromDB);
    };
    
    const fetchData = async () => {
      if (
        recoveryInfo.score === undefined ||
        sleepInfo.score === undefined ||
        cycleInfo.score === undefined
      ) {
        await hitDatabase();
      } else {
        console.log(
          "Attempted to re-render despite not needing to hit db again"
        );
      }
    };

    fetchData();
  }, [recoveryInfo, sleepInfo, cycleInfo, workoutInfo]);

  useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: whoopRef.current,
        start: "center top",
        end: "center top",
        toggleActions: "play none none reverse",
      },
    });

    timeline.to(whoopRef.current, {
      y: "-200px",
      duration: 0.5,
      ease: "power3.inOut",
    });

    return () => {
      if (timeline.scrollTrigger) timeline.scrollTrigger.kill();
    };
  }, []);

  // Disable scrolling when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className={styles.whoop} ref={whoopRef} onClick={toggleModal}>
        <Recovery recoveryScore={recoveryInfo.score ? recoveryInfo.score : 0} />
      </div>
      <WhoopModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        sleepInfo={sleepInfo}
        cycleInfo={cycleInfo}
        workoutInfo={workoutInfo}
        recoveryInfo={recoveryInfo}
      />
    </>
  );
};

export default Whoop;
