import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Ladderl from "./pages/projects/Ladderl";
import Jarvis from "./pages/projects/Jarvis";
import MagOS from "./pages/projects/MagOS";
import MaguireCodes from "./pages/projects/MaguireCodes";
import WeightClub from "./pages/projects/WeightClub";
import Plentyofolios from "./pages/projects/Plentyofolios";
import NotFound from "./pages/NotFound";
import { LoadingProvider } from "./context/LoadingContext";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-5521EPHS8K");
    // if (process.env.NODE_ENV === 'production') clarity.init('ckyi29i4x1');
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route
          path="ladderl"
          element={<Ladderl nextProjectName={"Plentyofolios"} />}
        />
        <Route path="jarvis" element={<Jarvis nextProjectName={"MagOS"} />} />
        <Route
          path="magos"
          element={<MagOS nextProjectName={"WeightClub"} />}
        />
        <Route
          path="maguirecodes"
          element={<MaguireCodes nextProjectName={"Ladderl"} />}
        />
        <Route
          path="weightclub"
          element={<WeightClub nextProjectName={"MaguireCodes"} />}
        />
        <Route
          path="plentyofolios"
          element={<Plentyofolios nextProjectName={"Jarvis"} />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );

  return (
    <LoadingProvider>
      <RouterProvider router={router} />
    </LoadingProvider>
  );
}

export default App;
