import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"; // To determine the current route
import { gsap } from "gsap"; // GSAP for animation
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from "../styles/Header.module.css";
import CustomLink from "../Link";
import Whoop from "./Whoop";
import Memoji from "./Memoji";

gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  const location = useLocation();
  const selectedBackgroundRef = useRef(null);

  useEffect(() => {
    // Calculate the position and animate the background based on the current route
    const path = location.pathname;

    if (path[0] === "/" && path.length > 1 && path !== "/about") {
      //path is a project, make background centered and a circle
      gsap.to(selectedBackgroundRef.current, {
        opacity: 0,
        x: `50%`,
        duration: 0.5, // Animation duration
        ease: "power3.inOut", // Easing function
      });
    } else {
      // If the path is not /about, then it must be /, so we animate to 0%
      const currentIndex = location.pathname === "/about" ? 1 : 0;
      gsap.to(selectedBackgroundRef.current, {
        x: `${currentIndex * 100}%`, // Adjust this calculation based on your layout
        opacity: 1,
        duration: 0.5, // Animation duration
        width: `50%`,
        ease: "expo.inOut", // Easing function
      });
    }
  }, [location]);

  return (
    <header className={styles.header}>
      <Memoji />
      <nav>
        <div ref={selectedBackgroundRef} className={styles.navBackground}></div>
        <CustomLink to="/">Work</CustomLink>
        <CustomLink to="/about">About</CustomLink>
      </nav>
      <Whoop />
    </header>
  );
};

export default Header;
