// Create a LoadingSpinner component that simply displays a loading spinner

import React from "react";
import styles from "./styles/LoadingSpinner.module.css";

const LoadingSpinner = () => {
  return (
    <div className={styles.loadingSpinner}>
      <div className={styles.loadingSpinnerInner}></div>
    </div>
  );
};

export default LoadingSpinner;
