import axios from "axios";

/*
  "records": [
{
"cycle_id": 93845,
"sleep_id": 10235,
"user_id": 10129,
"created_at": "2022-04-24T11:25:44.774Z",
"updated_at": "2022-04-24T14:25:44.774Z",
"score_state": "SCORED",
"score": {
"user_calibrating": false,
"recovery_score": 44,
"resting_heart_rate": 64,
"hrv_rmssd_milli": 31.813562,
"spo2_percentage": 95.6875,
"skin_temp_celsius": 33.7
}
}
],
*/

/*
  Returns: The current recovery score.
*/
export const getRecoveryScore = async () => {
  try {
    const response = await axios.get(
      "https://backend-whoop-portfolio-111c41d4609e.herokuapp.com/getRecovery"
    );
    const recovery = {
      score: response.data.records[0].score.recovery_score,
      hr: response.data.records[0].score.resting_heart_rate,
      hrv: response.data.records[0].score.hrv_rmssd_milli,
    };
    return recovery;
  } catch (error) {
    console.error(error);
  }
};

/*
{
"records": [
{
"id": 93845,
"user_id": 10129,
"created_at": "2022-04-24T11:25:44.774Z",
"updated_at": "2022-04-24T14:25:44.774Z",
"start": "2022-04-24T02:25:44.774Z",
"end": "2022-04-24T10:25:44.774Z",
"timezone_offset": "-05:00",
"nap": false,
"score_state": "SCORED",
"score": {
"stage_summary": {
"total_in_bed_time_milli": 30272735,
"total_awake_time_milli": 1403507,
"total_no_data_time_milli": 0,
"total_light_sleep_time_milli": 14905851,
"total_slow_wave_sleep_time_milli": 6630370,
"total_rem_sleep_time_milli": 5879573,
"sleep_cycle_count": 3,
"disturbance_count": 12
},
"sleep_needed": {
"baseline_milli": 27395716,
"need_from_sleep_debt_milli": 352230,
"need_from_recent_strain_milli": 208595,
"need_from_recent_nap_milli": -12312
},
"respiratory_rate": 16.11328125,
"sleep_performance_percentage": 98,
"sleep_consistency_percentage": 90,
"sleep_efficiency_percentage": 91.69533848
}
}
],
*/

/*
  Returns: The current sleep start and end times and sleep performance score.
*/
export const getSleepScore = async () => {
  try {
    const response = await axios.get(
      "https://backend-whoop-portfolio-111c41d4609e.herokuapp.com/getSleep"
    );
    const sleep = {
      start: response.data.records[0].start,
      end: response.data.records[0].end,
      score: response.data.records[0].score.sleep_performance_percentage,
    };
    return sleep;
  } catch (error) {
    console.error(error);
  }
};

/*
  {
"records": [
{
"id": 93845,
"user_id": 10129,
"created_at": "2022-04-24T11:25:44.774Z",
"updated_at": "2022-04-24T14:25:44.774Z",
"start": "2022-04-24T02:25:44.774Z",
"end": "2022-04-24T10:25:44.774Z",
"timezone_offset": "-05:00",
"score_state": "SCORED",
"score": {
"strain": 5.2951527,
"kilojoule": 8288.297,
"average_heart_rate": 68,
"max_heart_rate": 141
}
}
],
*/

/*
  Returns: The current cycle, with a strain score and kilojoule count.
*/
export const getCycleScore = async () => {
  try {
    const response = await axios.get(
      "https://backend-whoop-portfolio-111c41d4609e.herokuapp.com/getCycle"
    );
    const cycle = {
      start: response.data.records[0].start,
      end: response.data.records[0].end,
      score: response.data.records[0].score.strain,
      kilojoule: response.data.records[0].score.kilojoule,
    };
    return cycle;
  } catch (error) {
    console.error(error);
  }
};

/*
  {
"records": [
{
"id": 1043,
"user_id": 9012,
"created_at": "2022-04-24T11:25:44.774Z",
"updated_at": "2022-04-24T14:25:44.774Z",
"start": "2022-04-24T02:25:44.774Z",
"end": "2022-04-24T10:25:44.774Z",
"timezone_offset": "-05:00",
"sport_id": 1,
"score_state": "SCORED",
"score": {
"strain": 8.2463,
"average_heart_rate": 123,
"max_heart_rate": 146,
"kilojoule": 1569.34033203125,
"percent_recorded": 100,
"distance_meter": 1772.77035916,
"altitude_gain_meter": 46.64384460449,
"altitude_change_meter": -0.781372010707855,
"zone_duration": {}
}
}
],
*/

/*
  Returns: A list of workouts, each with a start and end time, a sport, and a strain score.
*/
export const getWorkoutInformation = async () => {
  try {
    const response = await axios.get(
      "https://backend-whoop-portfolio-111c41d4609e.herokuapp.com/getWorkout"
    );
    const workouts = [];
    const today = new Date();

    for (let i = 0; i < response.data.records.length; i++) {
      // Check if the workout is today
      if (
        response.data.records[i].start.slice(0, 10) !==
        today.toISOString().slice(0, 10)
      ) {
        continue;
      }
      const workout = {
        start: response.data.records[i].start,
        end: response.data.records[i].end,
        sport: response.data.records[i].sport_id,
        score: response.data.records[i].score.strain,
      };
      workouts.push(workout);
    }
    return workouts;
  } catch (error) {
    console.error(error);
  }
};
