import React from "react";
import CustomLink from "../Link";
import BentoBox from "../BentoBox";
import styles from "../styles/Bento.module.css";
import bentoAsset from "../../assets/BentoLadderl.webp";

const BentoLadderl = () => {
  return (
    <CustomLink to="/ladderl" className={styles.bentoLadderl}>
      <BentoBox>
        <h3>Word Puzzle</h3>
        <h2>Ladderl</h2>
        <img src={bentoAsset} alt="BentoLadderl" className={styles.bentoLadderlImg} />
      </BentoBox>
    </CustomLink>
  );
};

export default BentoLadderl;
