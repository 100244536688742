import React from 'react';
import styles from './styles/Footer.module.css';

const HoverCard = ({ trigger, children }) => {
  return (
    <div className={styles.hoverCardContainer}>
      {trigger}
      <div className={styles.card}>{children}</div>
    </div>
  );
};

export default HoverCard;
