// Utility function to convert time to 12-hour format
export const formatTo12Hour = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

// Function to subtract hours from a given time
export const subtractHours = (timeString, hoursToSubtract) => {
  const [hour, minute] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hour, minute);
  date.setHours(date.getHours() - hoursToSubtract);
  return date;
};
