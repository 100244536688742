// Create a 404 page
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import styles from "./styles/NotFound.module.css";
import CustomLink from "../components/Link";

const NotFound = () => {
  const cta = "#f0f0f0";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta); // Set the color of the CTA button
  }, []);
  const isMobile = window.innerWidth < 768;

  return (
    <>
      <Helmet>
        <title>Maguire McMahon · Not Found</title>
        <meta name="theme-color" content="#ffffff"></meta>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="#ffffff"
        ></meta>
      </Helmet>
      <div className={styles.notFoundContainer}>
        <h1>4<svg width={isMobile ? "5rem" : "10rem"} height={isMobile ? "5rem" : "10rem"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill="white"/></svg>4</h1>
        <p>
          The page you are looking for does not exist. <br /> Try these instead.
        </p>
        <div className={styles.tryLinks}>
          <CustomLink to="/">Home</CustomLink>
          <CustomLink to="/about">About</CustomLink>
          <CustomLink to="/#projects">Projects</CustomLink>
        </div>
      </div>
    </>
  );
};

export default NotFound;
