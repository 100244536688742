import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring"; // Import useSpring and animated from react-spring
import { gsap } from "gsap";
import styles from "../styles/Header.module.css";
import whoopLogo from "../../assets/whoopLogo.png";

const Recovery = ({ recoveryScore = 0 }) => {
  const strokeType = { green: "#16ec06", yellow: "#FFDE00", red: "#FF0026" };
  const [fillColor, setFillColor] = useState(strokeType.red);
  const logoRef = useRef(null);

  const radius = 46;
  const circumference = 2 * Math.PI * radius;

  // React-spring animation for recoveryScoreDisplayed
  const props = useSpring({
    recoveryScoreDisplayed: recoveryScore,
    config: { tension: 280, friction: 60 }, // Adjust tension and friction as needed for desired animation feel
  });

  const offsetProps = useSpring({
    offset: circumference * (1 - recoveryScore / 100),
    config: { tension: 280, friction: 60 }, // Adjust tension and friction as needed for desired animation feel
  });

  // Make the logo spin if the recovery score is 0, otherwise display the logo normally, finishing the spin animation after the animation is complete
  const tl = gsap.timeline();

  function spin(tl) {
    tl.to(logoRef.current, {
      rotation: 360,
      duration: 1,
      ease: "power3.out",
      onComplete: () => {
        tl.set(logoRef.current, { rotation: 0 });
        tl.to(logoRef.current, {
          rotation: 360,
          duration: 1,
          ease: "power3.out",
        });
      },
    });
  }

  useEffect(() => {
    if (recoveryScore === undefined || recoveryScore === 0) {
      spin(tl);
    } else {
      tl.clear();
      tl.to(logoRef.current, {
        rotation: 360,
        duration: 1,
        ease: "power3.out",
      });
    }
  }, [recoveryScore, tl]);

  useEffect(() => {
    if (recoveryScore === undefined || recoveryScore === 0) {
      setFillColor("#283339");
    } else if (recoveryScore >= 66) {
      setFillColor("#16ec06");
    } else if (recoveryScore >= 34) {
      setFillColor("#FFDE00");
    } else {
      setFillColor("#FF0026");
    }
  }, [recoveryScore]);

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <div className={styles.recoveryCircle}>
        <img
          src={whoopLogo}
          alt="Whoop Logo"
          className={`${styles.whoopLogo}`}
          ref={logoRef}
        />
        <div className={styles.arrowIndicator}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 7L5.5 5.5M15 7L16.5 5.5M5.5 16.5L7 15M11 5L11 3M5 11L3 11M17.1603 16.9887L21.0519 15.4659C21.4758 15.3001 21.4756 14.7003 21.0517 14.5346L11.6992 10.8799C11.2933 10.7213 10.8929 11.1217 11.0515 11.5276L14.7062 20.8801C14.8719 21.304 15.4717 21.3042 15.6375 20.8803L17.1603 16.9887Z"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <svg
          width={isMobile ? "40" : "65"}
          height={isMobile ? "40" : "65"}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Background circle */}
          <circle
            cx="50"
            cy="50"
            r={radius}
            stroke="#283339"
            strokeWidth="8"
            fill="none"
          />
          {/* Foreground circle */}
          <animated.circle
            cx="50"
            cy="50"
            r={radius}
            stroke={fillColor}
            strokeWidth="8"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={offsetProps.offset}
            transform="rotate(-90 50 50)"
          />
        </svg>
      </div>
      <div
        className={styles.recoveryTextContainer}
        style={{ color: fillColor }}
      >
        <animated.p className={styles.recoveryTextPercent}>
          {props.recoveryScoreDisplayed.to((val) => `${val.toFixed(0)}%`)}
        </animated.p>
        <p className={styles.recoveryText}>Recovery</p>
      </div>
    </>
  );
};

export default Recovery;
