import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import "../globals.css";
import gsap from "gsap";

const ThreeDScene = ({ cta }) => {
  const mountRef = useRef(null);
  const currentColor = useRef(new THREE.Color(cta));
  // console.log("currentColor", currentColor.current);

  useEffect(() => {
    // Scene, Camera, Renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x080808);
    renderer.shadowMap.enabled = true;

    if (!mountRef.current.children.length) {
      mountRef.current.appendChild(renderer.domElement);
    }

    // Adding a point light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
    scene.add(ambientLight);

    // Setting camera position
    camera.position.set(0, 3, 15);
    if (window.innerWidth < 440) {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.position.set(0, 3, 20);
    }

    // Adding a group of letters
    let width = 1;
    let height = 3;
    let dotRadius = width / 2;
    const firstM = createM(1, 3, 2, 0xff0000); // Adjust dimensions and color as needed
    const firstI = createI(1, 3, 2, 0xff0000, currentColor.current, 10); // Adjust dimensions, colors, and intensity as needed
    const secondM = firstM.clone();
    // Positioning the letters in the scene
    firstM.position.x = -4;
    firstI.position.x = 0;
    secondM.position.x = 4;
    firstM.rotateY(Math.PI / 4);
    secondM.rotateY(-Math.PI / 4);
    scene.add(firstM);
    scene.add(firstI);
    scene.add(secondM);

    //create a floor
    const planeGeometry = new THREE.PlaneGeometry(100, 100, 100, 100);
    const planeMaterial = new THREE.MeshStandardMaterial({
      color: 0xa0a0a0,
      roughness: .8,
      metalness: .8,
    });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.receiveShadow = true;
    plane.rotation.x = -Math.PI / 2;
    plane.position.y = -1;
    scene.add(plane);

    // Animation loop
    const animate = function () {
      requestAnimationFrame(animate);

      renderer.render(scene, camera);
    };

    animate();

    // Handle resizing
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      renderer.setSize(innerWidth, innerHeight);
      camera.aspect = innerWidth / innerHeight;
      camera.updateProjectionMatrix();
    };

    const onMouseMove = (event) => {
      if (mountRef.current) {
        const bounds = mountRef.current.getBoundingClientRect();
        const x = event.clientX - bounds.left - bounds.width / 2;
        const y = event.clientY - bounds.top - bounds.height / 2;
        // Normalize and limit movement to 50 pixels
        const maxMovement = 1;
        const moveX = THREE.MathUtils.clamp(
          (x / bounds.width) * 2 * maxMovement,
          -maxMovement,
          maxMovement
        );
        const moveY = THREE.MathUtils.clamp(
          (-y / bounds.height) * 2 * maxMovement,
          -maxMovement,
          maxMovement
        );

        // Apply movement to the dot of the 'i'
        if (firstI && firstI.children.length > 1) {
          firstI.children[1].position.x = moveX;
          firstI.children[1].position.y = height / 2 + dotRadius * 3 + moveY;
        }

        // Apply movement to the light of the 'i'
        if (firstI && firstI.children.length > 2) {
          firstI.children[2].position.x = moveX;
          firstI.children[2].position.y = height / 2 + dotRadius * 3 + moveY;
        }
      }
    };
    const dot = firstI.children[1];
    const dotLight = firstI.children[2];
    const dotLightPosition = dotLight.position;
    const dotLightPositionX = dotLightPosition.x;
    const dotLightPositionY = dotLightPosition.y;
    const dotLightPositionZ = dotLightPosition.z;
    const dotLightPositionXMax = dotLightPositionX + 2;
    const dotLightPositionXMin = dotLightPositionX - 2;
    const dotLightPositionYMax = dotLightPositionY + 1;
    const dotLightPositionYMin = dotLightPositionY;
    const dotLightPositionZMax = dotLightPositionZ + 0;
    const dotLightPositionZMin = dotLightPositionZ - 0;
    const swayDotLight = () => {
      if (firstI && firstI.children.length > 2) {
        const dotLightPositionXNew = THREE.MathUtils.randFloat(
          dotLightPositionXMin,
          dotLightPositionXMax
        );
        const dotLightPositionYNew = THREE.MathUtils.randFloat(
          dotLightPositionYMin,
          dotLightPositionYMax
        );
        const dotLightPositionZNew = THREE.MathUtils.randFloat(
          dotLightPositionZMin,
          dotLightPositionZMax
        );
        gsap.to(dotLight.position, {
          duration: 1,
          x: dotLightPositionXNew,
          y: dotLightPositionYNew,
          z: dotLightPositionZNew,
          onComplete: swayDotLight,
        });
        gsap.to(
          dot.position,
          {
            duration: 1,
            x: dotLightPositionXNew,
            y: dotLightPositionYNew,
            z: dotLightPositionZNew - 2,
          },
          "-=1"
        );
      }
    };

    if (window.innerWidth > 768) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("resize", handleResize);
    } else {
      swayDotLight();
    }

    return () => {
      // Remove event listener when the component unmounts
      if (window.innerWidth > 768) {
        window.removeEventListener("resize", handleResize);
        renderer.domElement.removeEventListener("mousemove", onMouseMove);
      }
    };
  }, [currentColor]);

  useEffect(() => {
    setTimeout(() => {
      if (mountRef.current) {
        currentColor.current = new THREE.Color(cta);
      }
    }, 1000);
  }, [cta]);

  //   HELPER FUNCTIONS
  function createM(width, height, depth, color) {
    const material = new THREE.MeshStandardMaterial({
      color: 0x808080,
      roughness: 0.7,
      metalness: 0.2,
    });

    // Legs
    const leg1 = new THREE.Mesh(
      new THREE.BoxGeometry(width, height, depth),
      material
    );
    const leg2 = leg1.clone();
    const leg3 = leg1.clone();

    // Middle parts
    const middle1 = new THREE.Mesh(
      new THREE.BoxGeometry(width, height / 3, depth),
      material
    );
    const middle2 = new THREE.Mesh(
      new THREE.BoxGeometry(width, height / 3, depth),
      material
    );

    // Positioning legs
    leg1.position.x = -1.5 * width;
    leg2.position.x = 0; // middle leg
    leg3.position.x = 1.5 * width;

    // Positioning middle parts
    middle1.position.set(-0.5 * width, height / 3, 0);
    middle2.position.set(0.5 * width, height / 3, 0);

    // Grouping parts together
    [leg1, leg2, leg3, middle1, middle2].forEach((part) => {
      part.castShadow = true;
      part.receiveShadow = true;
    });
    const group = new THREE.Group();
    group.add(leg1, middle1, middle2, leg2, leg3);
    return group;
  }

  function createI(width, height, depth, color, lightColor, lightIntensity) {
    const material = new THREE.MeshStandardMaterial({
      color: 0x808080,
      roughness: 0.7,
      metalness: 0.2,
    });

    // Stem of the 'i'
    const stem = new THREE.Mesh(
      new THREE.BoxGeometry(width, height, depth),
      material
    );

    // Sphere for the dot
    const dotRadius = width / 2; // Adjust the radius as needed
    const dot = new THREE.Mesh(
      new THREE.SphereGeometry(dotRadius, 32, 32),
      material
    );

    // Positioning the dot above the stem
    dot.position.y = height / 2 + dotRadius * 3;

    // Create a PointLight at the position of the dot
    const light = new THREE.PointLight(lightColor, lightIntensity, 4000);
    light.position.copy(dot.position);
    light.position.y -= dotRadius * 2;
    light.position.z += dotRadius * 7;

    stem.castShadow = true;
    stem.receiveShadow = true;
    dot.castShadow = true;
    dot.receiveShadow = true;
    light.castShadow = true;

    const group = new THREE.Group();
    group.add(stem, dot, light);
    return group;
  }

  return <div ref={mountRef} className="threeDScene" />;
};

export default ThreeDScene;
