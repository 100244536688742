import React, { useEffect } from "react";
import "../../globals.css";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero";
import ProjectContent from "../../components/projects/ProjectContent";
import styles from "../styles/Projects.module.css";
import ProjectOutboundButton from "../../components/projects/ProjectOutboundButton";
import ProjectVideo from "../../components/projects/ProjectVideo";

const Ladderl = ({ nextProjectName }) => {
  const cta = "#89A8E4";
  const nextcta = "#7662D7";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta);
    document.documentElement.style.setProperty("--next-cta", nextcta);
  }, []);
  const nextUrl = "/" + nextProjectName.toLowerCase();

  const thumbnail = "/assets/LadderlThumbnail.png";

  const isMobile = window.innerWidth < 768;
  return (
    <>
      <Helmet>
        <title>Maguire McMahon · Ladderl</title>
        <meta name="theme-color" content={cta}></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content={cta}></meta>
        <meta
          name="description"
          content="Ladderl is a simple, yet unique word puzzle game created using HTML, CSS, and JS."
        />
      </Helmet>
      <div className={`${styles.projectLayout} page-layout`}>
        <Hero h1={"ladderl"} h2={"a simply unique word-puzzle"} cta={cta}>
          <div className={styles.projectHeroInfo}>
            <div className={styles.verticalFlex}>
              <h3>Role</h3>
              <p>Full-Stack Developer</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Tech Stack</h3>
              <p>HTML, CSS, JS</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Timeline</h3>
              <p>Fall '23</p>
            </div>
          </div>
        </Hero>

        {/* MAIN CONTENT */}
        <ProjectContent>
          <div className={styles.projectContent}>
            <div className={styles.projectContentBackground}></div>
            {isMobile ? (
              <ProjectVideo
                videoSource="/assets/LadderlDesktopVid.mp4"
                posterImage={thumbnail}
                type="mobile"
              />
            ) : (
              <ProjectVideo
                videoSource="/assets/LadderlDesktopVid.mp4"
                posterImage={thumbnail}
                type="desktop"
              />
            )}
            <div className={styles.projectDescription}>
              <h3>Overview</h3>
              <p>
                Ladderl was a small project I created to explore the booming
                world of word puzzles, popularized by games like Wordle and
                Connections. I wanted to create a simple, yet unique word puzzle
                that could be played on any device. The rules usually confuse
                people at first, but once they figure it out they can pour hours
                into it.
              </p>
              <br />
              <h3>Features</h3>
              <ul>
                <li>Dark/Light mode</li>
                <li>Mobile-first design</li>
                <li>Daily challenges</li>
                <li>Custom sharing with Emojis similar to Wordle</li>
                <li>Shortest-path algorithm upon winning</li>
              </ul>
              <p>
                The main logic of the game was fairly simple to implement. The
                difficult part was creating a UI that was both intuitive and
                responsive. I took lots of inspiration from other web based word
                puzzles, and designed it with a mobile-first approach. I also
                wanted to make sure that the game was accessible to all, so I
                made sure to include a dark mode that matches your device
                preference.
              </p>
              <p>
                The feature I am most proud of is the ability to see the
                shortest path upon completion. I went through many
                implementations of how the "word logic" was decided. Originally
                calling on APIs for everything, I learned the technical
                challenges and user experience hit this took, having to wait
                between actions for the server to process the command. I
                eventually decided to implement the logic on the client side,
                and only call on the server to create the list of words on the
                client side. This allowed for a much smoother user experience,
                and allowed me to implement the shortest path feature on the
                client side without a significant runtime.
              </p>
              <p>
                Finally, another really nice feature I implemented was the
                ability to generate the daily challenges for as many dates as I
                would like on my own computer. Since this data is simply stored
                in a JSON file, it could easily be intercepted, but this
                information only gets you so far because you still have to find
                the path!
              </p>
              <br />
              <h3>Future plans</h3>
              <p>
                I have generated my list of daily words for all of 2024, and I
                plan on adding a mobile-app companion and releasing it to the
                Google Play Store and Apple App Store. I also plan on adding a
                "friends" feature, where you can compete with your friends to
                see who can find the shortest path with a pair of words.
                Finally, I hope to add a database, which allows you to see how
                your path ranks up against everyone in the world!
              </p>
            </div>
          </div>

          {/* CTA BUTTONS */}
          <div className={styles.buttonContainer}>
            <ProjectOutboundButton
              url="https://mimcmahon20.github.io/Ladderl/"
              text={"Play now"}
              buttonType="source"
            />
            <ProjectOutboundButton
              url="https://github.com/mimcmahon20/Ladderl"
              text={"Source Code"}
              buttonType="source"
            />
            <ProjectOutboundButton
              url={nextUrl}
              text={nextProjectName}
              buttonType="outbound"
            />
          </div>
        </ProjectContent>
      </div>
    </>
  );
};

export default Ladderl;
