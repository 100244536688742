// Create a project video component that takes in a video source and a poster image and type (desktop or mobile) which determines the type of video controls etc.
// Use gsap to start it with no border radius and then gradually increase the border radius to 80px as the user scrolls down the page and the video is centered in the viewport
// Use gsaps scroll trigger to start the video with no border radius and then gradually increase the border radius to 80px as the user scrolls down the page and the video is centered in the viewport
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styles from "../../pages/styles/Projects.module.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function ProjectVideo({ videoSource, posterImage, type }) {
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    // Set up the GSAP timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: videoContainerRef.current,
        start: "top center",
        end: "center center",
        scrub: true,
        toggleActions: "play none none none",
        markers: false,
      },
    });

    // Animate the video
    tl.fromTo(
      videoRef.current,
      {
        borderRadius: 20,
      },
      {
        borderRadius: 0,
      }
    );

    return () => {
      // Clean up the GSAP timeline
      tl.kill();
    };
  }, []);

  if (type === "mobile") {
    return (
      <div className={styles.projectMainVideo} ref={videoContainerRef}>
        <video ref={videoRef} controls loop poster={posterImage}>
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
  return (
    <div className={styles.projectMainVideo} ref={videoContainerRef}>
      <video ref={videoRef} controls autoPlay loop>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
