import React from "react";
import styles from "../styles/WhoopModal.module.css";
import whoopMoon from "../../assets/whoopMoon.png";

export default function WhoopSleep({ score, start, end }) {
  return (
    <div className={`${styles.container}`}>
      <div className={styles.initialContainer}>
        <p className={`${styles.text} ${styles.sleepBg}`}>
          <img src={whoopMoon} alt="moon" />
          {score}
        </p>
        <p className={styles.header}>Sleep</p>
      </div>
      <div className={styles.time}>
        <p>{end}</p>
        <p>{start}</p>
        <div className={`${styles.timeLine} ${styles.sleepBg}`}></div>
      </div>
    </div>
  );
}
