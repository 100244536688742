import React from "react";
import CustomLink from "../Link";
import BentoBox from "../BentoBox";
import styles from "../styles/Bento.module.css";
import bentoAsset from "../../assets/BentoPlenty.webp";
import safariAsset from "../../assets/SafariOverlay.png";

const BentoPlenty = () => {
  return (
    <CustomLink to="/plentyofolios" className={styles.bentoPlenty}>
      <BentoBox>
        <h3>Design Inspiration</h3>
        <h2>Plentyofolios</h2>
        <div className={styles.bentoPlentyImgContainer}>
          
          <img
            src={bentoAsset}
            alt="Bento Plentyofolios"
            className={styles.bentoPlentyImg}
          ></img>
          <img
            src={safariAsset}
            alt="Safari Overlay"
            className={styles.bentoSafariOverlay}
          />
        </div>
      </BentoBox>
    </CustomLink>
  );
};

export default BentoPlenty;
