import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/Hero";
import styles from "./styles/Home.module.css";
import BentoLadderl from "../components/projects/BentoLadderl";
import BentoMaguireCodes from "../components/projects/BentoMaguireCodes";
import BentoJarvis from "../components/projects/BentoJarvis";
import BentoMagOS from "../components/projects/BentoMagOS";
import BentoPlenty from "../components/projects/BentoPlenty";
import BentoWeightClub from "../components/projects/BentoWeightClub";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const projectsRef = useRef(null); // Reference to the container of projects
  const cta = "#ffffff";

  useEffect(() => {
    window.scrollTo(0, -10); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta);

    // Check if the URL contains the hash #projects
    if (window.location.hash === "#projects") {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [projectsRef]);

  return (
    <>
      <Helmet>
        <title>Maguire McMahon · Work</title>
        <meta name="theme-color" content={cta}></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content={cta}></meta>
      </Helmet>
      <div className="page-layout">
        <Hero
          h1={`hi! I'm maguire mcmahon`}
          h2="a full-stack developer with a passion for visuals"
          cta={cta}
        />
        <div
          className={styles.projectsContainer}
          ref={projectsRef}
          id="projects"
        >
          <BentoPlenty />
          <BentoWeightClub />
          <BentoJarvis />
          <BentoLadderl />
          <BentoMaguireCodes />
          <BentoMagOS />
        </div>
      </div>
    </>
  );
};

export default Home;
