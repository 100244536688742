import React from "react";
import CustomLink from "../Link";
import BentoBox from "../BentoBox";
import styles from "../styles/Bento.module.css";
import bentoAsset from "../../assets/BentoMagOS.webp";

const BentoMagOS = () => {
  return (
    <CustomLink to="/magos" className={styles.bentoMagOS}>
      <BentoBox>
        <h3>Web App</h3>
        <h2>MagOS</h2>
        <img src={bentoAsset} alt="BentoMagOS" className={styles.bentoMagOSImg} />
      </BentoBox>
    </CustomLink>
  );
};

export default BentoMagOS;
