import React from "react";
import CustomLink from "../Link";
import BentoBox from "../BentoBox";
import styles from "../styles/Bento.module.css";
import bentoAsset from "../../assets/BentoMaguireCodes.webp";

const BentoMaguireCodes = () => {
  return (
    <CustomLink to="/maguirecodes" className={styles.bentoMaguireCodes}>
      <BentoBox>
        <h3>Tech Blog</h3>
        <h2>MaguireCodes</h2>
        <img src={bentoAsset} alt="BentoMaguireCodes" className={styles.bentoMaguireCodesImg} />
      </BentoBox>
    </CustomLink>
  );
};

export default BentoMaguireCodes;
