import React, { useRef, useState, useEffect } from "react";
import styles from "../styles/WhoopModal.module.css";
import whoopLogo from "../../assets/whoopLogo.png";

const WhoopModalCircle = ({ children, recoveryScore, strainScore }) => {
  const [fillColor, setFillColor] = useState("#FF0026");
  const circleRef = useRef(null);
  const isMobile = window.innerWidth < 768;
  const radiusSmall = isMobile ? 35 : 55; // Updated Radius
  const radiusLarge = isMobile ? 50 : 80; // Updated Radius

  const circumferenceSmall = 2 * Math.PI * radiusSmall;
  const circumferenceLarge = 2 * Math.PI * radiusLarge;

  const fillPercentSmall = recoveryScore;
  const fillPercentLarge = strainScore / 21 * 100;

  const offsetSmall = circumferenceSmall * (1 - fillPercentSmall / 100);
  const offsetPercentLarge = circumferenceLarge * (1 - fillPercentLarge / 100);

  const svgSizeSmall = radiusSmall * 2 + 8; // Ensure this size includes stroke width

  const svgSizeLarge = radiusLarge * 2 + 8; // Ensure this size includes stroke width

  useEffect(() => {
    if (recoveryScore >= 66) {
      setFillColor("#16ec06");
    } else if (recoveryScore >= 34) {
      setFillColor("#FFDE00");
    } else {
      setFillColor("#FF0026");
    }
  }, [recoveryScore]);

  return (
    <div className={styles.circleContainer}>
      {children}
      <img src={whoopLogo} alt="Whoop Logo" className={styles.whoopLogo} />
      <div className={styles.smallCircle} ref={circleRef}>
        <svg
          width={svgSizeSmall}
          height={svgSizeSmall}
          viewBox={`0 0 ${svgSizeSmall} ${svgSizeSmall}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Background circle */}
          <circle
            cx={svgSizeSmall / 2}
            cy={svgSizeSmall / 2}
            r={radiusSmall}
            stroke="#283339"
            strokeWidth={isMobile ? "6" : "8"}
            fill="none"
          />
          {/* Foreground circle */}
          <circle
            cx={svgSizeSmall / 2}
            cy={svgSizeSmall / 2}
            r={radiusSmall}
            stroke={fillColor}
            strokeWidth={isMobile ? "6" : "8"}
            fill="none"
            strokeDasharray={circumferenceSmall}
            strokeDashoffset={offsetSmall}
            transform={`rotate(-90 ${svgSizeSmall / 2} ${svgSizeSmall / 2})`}
          />
        </svg>
      </div>
      <div className={styles.largeCircle}>
        <svg
          width={svgSizeLarge}
          height={svgSizeLarge}
          viewBox={`0 0 ${svgSizeLarge} ${svgSizeLarge}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Background circle */}
          <circle
            cx={svgSizeLarge / 2}
            cy={svgSizeLarge / 2}
            r={radiusLarge}
            stroke="#283339"
            strokeWidth={isMobile ? "6" : "8"}
            fill="none"
          />
          {/* Foreground circle */}
          <circle
            cx={svgSizeLarge / 2}
            cy={svgSizeLarge / 2}
            r={radiusLarge}
            stroke="#0093E7"
            strokeWidth={isMobile ? "6" : "8"}
            fill="none"
            strokeDasharray={circumferenceLarge}
            strokeDashoffset={offsetPercentLarge}
            transform={`rotate(-90 ${svgSizeLarge / 2} ${svgSizeLarge / 2})`}
          />
        </svg>
      </div>
    </div>
  );
};

export default WhoopModalCircle;
